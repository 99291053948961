import { StoreContext } from '../../../configuration/StoreContext';
import { computed, observable, action } from 'mobx';
import { TAcceptanceMode } from '../models/TAcceptanceMode';
import { IAcceptanceConfiguration } from '../models';
import { TranslationStore } from '../../App/stores/TranslationStore';
import { IConfigurationStorage } from '../../../models/app/IConfigurationStorage';
import { IAcceptanceItem } from '../models/IAcceptanceItem';
import { ITextProps } from '@kurtosys/ksys-app-components/dist/components/base/Text/models/ITextProps';

export class AcceptanceStore {
	static componentKey: 'acceptance' = 'acceptance';
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}
	get translationStore(): TranslationStore {
		return this.storeContext.translationStore;
	}
	@computed
	get configuration(): IAcceptanceConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(AcceptanceStore.componentKey);
		}
	}
	@observable singleAccepted: boolean = false;
	@observable acceptedItems: string[] = [];

	@action
	toggleItem(key: string, value: boolean) {
		if (value) {
			if (this.acceptedItems.indexOf(key) < 0) {
				this.acceptedItems.push(key);
			}
		}
		else {
			this.acceptedItems = this.acceptedItems.filter(item => item !== key);
		}
	}

	@computed
	get mode(): TAcceptanceMode {
		return (this.configuration && this.configuration.mode) || 'SINGLE';
	}
	@computed
	get text(): string {
		const { selectionStore, translationStore } = this.storeContext;
		// Need to use placeholder here
		const rawText = (this.configuration && this.configuration.text) || 'I accept';
		const translatedValues = Object.keys(selectionStore.rawValues).reduce((acc: { [key: string]: string }, key) => {
			acc[key] = translationStore.translate(selectionStore.rawValues[key]);
			return acc;
		}, {});
		return this.translationStore.translate(rawText, translatedValues);
	}

	@computed
	get items(): IAcceptanceItem[] {
		const { selectionStore, translationStore } = this.storeContext;
		const items = this.configuration && this.configuration.items;

		if (items) {
			return items.map((item: IAcceptanceItem) => {
				const translatedValues = Object.keys(selectionStore.rawValues).reduce((acc: { [key: string]: string }, key) => {
					acc[key] = translationStore.translate(selectionStore.rawValues[key]);
					return acc;
				}, {});
				return {
					...item,
					text: this.translationStore.translate(item.text, translatedValues),
				};
			});
		}
		return [];
	}

	@computed
	get acceptedState() {
		if (this.mode === 'MULTIPLE') {
			const failCheck = (item: IAcceptanceItem) => !item.optional && this.acceptedItems.indexOf(item.key) < 0;
			return !this.items.some(failCheck);
		}
		return this.singleAccepted;
	}

	@computed
	get accepted(): boolean {
		return this.acceptedState && this.storeContext && this.storeContext.selectionStore && !!this.storeContext.selectionStore.hasAllSelections;
	}

	@computed
	get storage(): IConfigurationStorage | undefined {
		if (this.configuration && this.configuration.mode === 'STORAGE') {
			return this.configuration.storage;
		}
	}

	@computed
	get textProps(): Partial<ITextProps> | undefined {
		return this.configuration && this.configuration.textProps;
	}
}