import React, { Component } from 'react';
import {
	ILinksProps,
	ILinksState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Link from './styledComponents/Link';
import { ILinkProps } from './models/ILinkProps';
import { isNullOrEmpty } from '@kurtosys/ksys-app-template/dist/utils/typeChecks';

@inject('appStore', 'linksStore')
@observer
export class Links extends Component<ILinksProps, ILinksState> {
	static configurationKey: 'links' = 'links';
	static styleKey: 'links' = 'links';
	constructor(props: ILinksProps) {
		super(props);
	}
	render() {
		const { className, linksStore } = this.props;
		if (!linksStore) {
			return null;
		}

		const { links, show } = linksStore;

		if (!show || isNullOrEmpty(links)) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				{
					links.map((link: ILinkProps) => {
						return (
							<Link key={ link.text } href={ link.href } target={ link.target }>
								{ link.text }
							</Link>
						);
					})
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Links, 'links');

