import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { ModalStore } from '../components/Modal/stores/ModalStore';
import { DisclaimerStore } from '../components/Disclaimer/stores/DisclaimerStore';
import { HeaderStore } from '../components/Header/stores/HeaderStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { SelectionStore } from '../components/Selection/stores/SelectionStore';
import { SelectionFieldStore } from '../components/SelectionField/stores/SelectionFieldStore';
import { AcceptanceStore } from '../components/Acceptance/stores/AcceptanceStore';
import { CallToActionStore } from '../components/CallToAction/stores/CallToActionStore';
import { IconDropdownStore } from '../components/IconDropdown/stores/IconDropdownStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { SelectionWizardStore } from '../components/SelectionWizard/stores/SelectionWizardStore';
import { FootnoteStore } from '../components/Footnote/stores/FootnoteStore';
import { LinksStore } from '../components/Links/stores/LinksStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	modalStore: ModalStore;
	disclaimerStore: DisclaimerStore;
	headerStore: HeaderStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	selectionStore: SelectionStore;
	selectionFieldStore: SelectionFieldStore;
	acceptanceStore: AcceptanceStore;
	callToActionStore: CallToActionStore;
	iconDropdownStore: IconDropdownStore;
	queryStore: QueryStore;
	selectionWizardStore: SelectionWizardStore;
	footnoteStore: FootnoteStore;
	linksStore: LinksStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.disclaimerStore = new DisclaimerStore(this);
		this.selectionStore = new SelectionStore(this);
		this.selectionFieldStore = new SelectionFieldStore(this);
		this.headerStore = new HeaderStore(this);
		this.acceptanceStore = new AcceptanceStore(this);
		this.callToActionStore = new CallToActionStore(this);
		this.queryStore = new QueryStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.iconDropdownStore = new IconDropdownStore(this);
		this.modalStore = new ModalStore(this);
		this.selectionWizardStore = new SelectionWizardStore(this);
		this.footnoteStore = new FootnoteStore(this);
		this.linksStore = new LinksStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			modalStore: this.modalStore,
			disclaimerStore: this.disclaimerStore,
			headerStore: this.headerStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			selectionStore: this.selectionStore,
			selectionFieldStore: this.selectionFieldStore,
			acceptanceStore: this.acceptanceStore,
			callToActionStore: this.callToActionStore,
			iconDropdownStore: this.iconDropdownStore,
			queryStore: this.queryStore,
			selectionWizardStore: this.selectionWizardStore,
			footnoteStore: this.footnoteStore,
			linksStore: this.linksStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) { }
}
