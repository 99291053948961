import { StoreContext } from '../../../configuration/StoreContext';
import { KsysApiClient } from '@kurtosys/ksys-api-client/dist/KsysApiClient';
import { observable, reaction, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { GetApplicationAppConfig } from '@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { ListTranslations } from '@kurtosys/ksys-api-client/dist/requests/config/ListTranslations';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { ListCommentaries } from '@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries';
import { ListDisclaimers } from '@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers';
import { SearchDocuments } from '@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments';
import { SearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity';
import { GetTheme } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme';
import { RetrieveClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration';
import { SavedSearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity';
import { RetrieveDocument } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument';
import { Retrieve } from '@kurtosys/ksys-api-client/dist/requests/documents/Retrieve';
import { RetrieveDocumentZip } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip';

export class KurtosysApiStore extends StoreBase {
	@observable token: string = '';
	private clientInstance: KsysApiClient | undefined;
	@computed
	get wrappedToken(): { value: string } {
		return { value: this.token };
	}
	@computed
	get options(): IRequestOptions<any> {
		const { appStore } = this.storeContext;
		const serviceUrl = appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl;
		return {
			baseUrl: serviceUrl || this.getBaseAddress(),
		};
	}

	constructor(storeContext: StoreContext) {
		super(storeContext);
	}

	@computed
	get client(): IQueryContextClient {
		const { appStore } = this.storeContext;
		const serviceUrl = appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl;
		const token = this.wrappedToken;
		const options = this.options;
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: new RetrieveClientConfiguration(token, options),
				},
			},
			commentaries: {
				list: new ListCommentaries(token, options),
			},
			disclaimers: {
				list: new ListDisclaimers(token, options),
			},
			documents: {
				search: new SearchDocuments(token, options),
				retrieve: new RetrieveDocument(token, options),
				retrieveByProperty: new Retrieve(token, options),
				retrieveZip: new RetrieveDocumentZip(token, options),
			},
			entity: {
				search: new SearchEntity(token, options),
				savedSearchEntity: new SavedSearchEntity(token, options),
			},
			options: {
				baseUrl: serviceUrl || this.getBaseAddress(),
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return new GetApplicationAppConfig(this.wrappedToken, this.options);
	}

	@computed
	get listTranslations(): ListTranslations {
		return new ListTranslations(this.wrappedToken, this.options);
	}

	@computed
	get getTheme(): GetTheme {
		return new GetTheme(this.wrappedToken, this.options);
	}

	@action
	async initialize(): Promise<void> {

	}

	getBaseAddress(suffix: string = 'services/') {
		return this.manifest.getBaseUrl(suffix, this.storeContext.appStore.appParamsHelper);
	}
}