import { query, utils } from '@kurtosys/ksys-app-template';
import { StoreContext } from '../../../configuration/StoreContext';
import { QueryContext } from '@kurtosys/ksys-api-client/dist/query/QueryContext';
import { observable, action, computed } from 'mobx';
import { QueryStore as QueryStoreBase } from '@kurtosys/ksys-app-components/dist/shared/QueryStore';
import { IQueryOptions, IExecutionOptions, IQueryContext, IQueryContextCulture, IBatchPromiseElement, IBatchOptions } from '../../../models/commonTypes';
import { IInputs } from '../../../models/app/IInputs';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';

import { batchExecutePromises } from '@kurtosys/ksys-app-template/dist/utils/promise';
import { getStoreKey } from '../../../utils/store';
const Query = query.Query;
export class QueryStore extends QueryStoreBase {
	storeContext: StoreContext;
	@observable.ref context: IQueryContext | undefined = undefined;

	constructor(storeContext: StoreContext) {
		super();
		this.storeContext = storeContext;
	}

	@computed
	get client(): IQueryContextClient {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.client;
	}

	get inputs() {
		const { appStore } = this.storeContext;
		const { appParamsHelper } = appStore;
		return appParamsHelper.inputs;
	}

	@computed
	get executionOptions(): IExecutionOptions {
		const { appStore, translationStore } = this.storeContext;
		return {
			defaultContextParentKey: this.defaultContextParentKey,
			context: undefined,
			culture: appStore.getInput('culture'),
			fallbackCulture: appStore.configuration && appStore.configuration.culture && appStore.configuration.culture.default,
			translate: translationStore.translate,
			inputs: this.inputs,
		};
	}
	get defaultContextParentKey(): string {
		const { appStore } = this.storeContext;
		const { configuration, storeKey } = appStore;
		let response: string = storeKey;
		if (configuration) {
			const { data } = configuration;
			if (data) {
				const { key } = data;
				if (key) {
					response = key;
				}
			}
		}
		return response;
	}
	@action async initialize() {
		const { appStore, kurtosysApiStore } = this.storeContext;
		const { configuration, appParamsHelper, dataContextSeed } = appStore;
		if (configuration && configuration.data && configuration.data.context) {
			const contexts = Array.isArray(configuration.data.context) ? configuration.data.context : [configuration.data.context];
			const batchPromises: IBatchPromiseElement[] = [];
			const inputs: IInputs | undefined = appParamsHelper.inputs;
			const queryContextCulture: IQueryContextCulture = {
				sourceCulture: (configuration && configuration.culture && configuration.culture.base) || 'en-GB',
				targetCulture: (inputs && inputs.culture) || (configuration && configuration.culture && configuration.culture.default) || 'en-GB',
			};
			for (const context of contexts) {
				const queryContext = new QueryContext(context, dataContextSeed);
				batchPromises.push({
					identifier: {
						contextParentKey: configuration.data.key || this.defaultContextParentKey,
						contextKey: context.contextKey || 'default',
					},
					promiseFunc: () => queryContext.fetch(kurtosysApiStore.client, inputs || {}, queryContextCulture),
					responseFunc: (response: any, identifier: any) => {
						query.Query.contextStore.set({
							...identifier,
							value: {
								context: response,
								contextConfiguration: context,
							},
						});
					},
				});
			}
			const batchOptions: IBatchOptions = {
				maxBatchSize: 10,
			};
			await batchExecutePromises(batchPromises, batchOptions);
		}
	}
	query(options?: IQueryOptions, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (options) {
			const { translationStore } = this.storeContext;
			const translationHelper = translationStore && translationStore.translationHelper;
			const query = new Query(options, translationHelper);
			const workingExecutionOptions = query.mergeExecutionOptions(this.executionOptions, overrideExecutionOptions || {});
			const queryResponse = query.execute(workingExecutionOptions);
			return queryResponse;
		}
	}
	async entitiesRequest(id: string, inputs: object, contextParentKey: string = this.defaultContextParentKey, contextKey: string = 'default') {
		const contextAndConfiguration = query.Query.contextStore.get({ contextParentKey, contextKey });
		const { contextConfiguration } = contextAndConfiguration;
		if (contextConfiguration) {
			const { appStore } = this.storeContext;
			const { dataContextSeed } = appStore;
			const queryContext = new QueryContext(contextConfiguration, dataContextSeed);
			if (queryContext && contextConfiguration) {
				return await queryContext.fetchEntities(this.client, id, inputs, contextConfiguration, this.executionOptions);
			}
		}
	}
}