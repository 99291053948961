import { helpers, utils } from '@kurtosys/ksys-app-template';
import {
	IComponentStyle,
	CacheOptions,
	IApplicationAssetRegister,
	ISkeletonLoaders,
	ISkeletonLoader,
	IQueryContext,
	IPixelDimensions,
} from '../../../models/commonTypes';
import { getApplicationCode } from '../../../start';
import { StoreBase } from '../../../common/StoreBase';
import { CONFIGURATION } from '../../../configuration/development.config';
import { LIBRARY_COMPONENTS_CONFIGURATION } from '../../../configuration/libraryComponentsConfiguration';
import { STYLES } from '../../../configuration/development.styles';
import { IConfiguration } from '../../../models/app/IConfiguration';
import { IInputs } from '../../../models/app/IInputs';
import { IStyles } from '../../../models/app/IStyles';
import { IAppComponents } from '../models/IAppComponents';
import { IComponentConfigurations } from '../../../models/app/IComponentConfigurations';
import { IComponentStyles } from '../../../models/app/IComponentStyles';
import { observable, computed, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IGetApplicationAppConfigResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/config/IGetApplicationAppConfigResponseBody';
import { registerAppStart, registerAppLoaded, getAppHydration } from '../../../utils/initialize';
import { IAppConfiguration } from '../models/IAppConfiguration';
import { ICoreConfigurations } from '../../../models/app/ICoreConfigurations';
import { IAssets } from '@kurtosys/ksys-app-components/dist/models/IAssets';
import { Manifest } from '../../../configuration/Manifest';
import { getPreviewContext } from '../../../initialize';
import { ITheme } from '@kurtosys/ksys-app-components/dist/models/ITheme';
import { deepMergeObjectsWithOptions } from '@kurtosys/ksys-app-template/dist/utils/object';
import { IGetThemeResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/applicationManager/IGetThemeResponseBody';
import { getStoreKey } from '../../../utils/store';

export const DATA_CONTEXT_SEED_KEY = '__ksys-app-data-context-seed__';
export abstract class AppStoreBase extends StoreBase {
	abstract get hasData(): boolean;
	htmlElement: HTMLElement;
	storeKey: string;
	appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>;
	@observable.ref rawConfiguration: Partial<IConfiguration> = {};
	@observable.ref rawStyles: Partial<IStyles> = {};
	@observable.ref rawThemeResponse: IGetThemeResponseBody | undefined;
	@observable themeKey: string | undefined;
	// App loading states
	@observable isBootstrapped = false;

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, public manifest: Manifest) {
		super(storeContext);
		this.htmlElement = element;
		this.storeKey = getStoreKey(element, url, manifest);
		this.appParamsHelper = new helpers.AppParamsHelper(element, url);
		registerAppStart(manifest, this.appParamsHelper);
		this.themeKey = (this.appParamsHelper.rawAppParams && this.appParamsHelper.rawAppParams.themeKey) || 'default';
	}

	getTranslateFunction = () => {
		const { translationStore } = this.storeContext;
		return translationStore.translate;
	}

	// Left blank to allow override
	async customInitializeAfter() {

	}

	// Left blank to allow override
	async customInitializeBefore() {

	}

	@action
	async initialize() {
		const response = await this.getAppConfig();
		if (response) {
			this.rawStyles = response.applicationStyles as any as IStyles;
			this.rawConfiguration = response.applicationConfiguration as any as IConfiguration;
			const stopInitializeConfiguration = await this.loadPreviewContext();
			if (!stopInitializeConfiguration) {
				await this.initializeConfiguration();
			}
		}
	}

	@action
	async loadPreviewContext(): Promise<boolean> {
		let response = false;
		const applicationCode = getApplicationCode();
		const previewContext = getPreviewContext(applicationCode);
		if (previewContext) {
			if (previewContext.themeKey && this.themeKey !== previewContext.themeKey) {
				this.themeKey = previewContext.themeKey;
				await this.loadAppTheme();
			}
			if (previewContext.theme && this.rawThemeResponse) {
				this.rawThemeResponse = {
					...this.rawThemeResponse,
					theme: previewContext.theme,
				};
				this.rawStyles = previewContext.applicationStyles || { ...this.rawStyles };

			}
			if (previewContext.applicationStyles) {
				this.rawStyles = previewContext.applicationStyles;
			}
			if (previewContext.applicationConfiguration) {
				this.rawConfiguration = previewContext.applicationConfiguration;
				this.initializeConfiguration(true);
				response = true;
			}
		}
		return response;
	}

	@action
	initializeConfiguration = async (preventAppLoadedEventFire: boolean = false) => {
		const { kurtosysApiStore, translationStore, queryStore } = this.storeContext;
		const token = (this.configuration && this.configuration.token) || '';
		kurtosysApiStore.token = token;
		await this.customInitializeBefore();
		const promises: Promise<any>[] = [
			this.loadAppTheme(),
			translationStore.loadTranslations(),
			queryStore.initialize(),
		];
		await Promise.all(promises.map(p => p.catch(e => e)));
		await this.customInitializeAfter();
		this.isBootstrapped = true;
		if (!preventAppLoadedEventFire) {
			const { loadEventDelay = 0 } = (this.rawConfiguration && this.rawConfiguration.core) || {};
			setTimeout(() => {
				registerAppLoaded(this.manifest, this.appParamsHelper);
			}, loadEventDelay);
		}
	}

	@computed
	get skeletonLoaders(): ISkeletonLoaders | undefined {
		return this.coreConfigurations && this.coreConfigurations.skeletonLoaders;
	}

	@computed
	get skeletonLoader(): ISkeletonLoader | undefined {
		const skeletonLoaders = this.skeletonLoaders;
		if (skeletonLoaders) {
			const { loaders = [] } = skeletonLoaders;
			return loaders.find((loader) => {
				const { id, configurationKey: loaderConfigurationKey = 'default', styleKey: loaderStyleKey = 'default' } = loader;
				const { configurationKey = 'default', styleKey = 'default' } = this.appParamsHelper.rawAppParams || {};
				return id === this.manifest.ksysAppTemplateId && configurationKey === loaderConfigurationKey && styleKey === loaderStyleKey;
			});
		}
	}

	@computed
	get rawTheme(): ITheme {
		return (this.rawThemeResponse && this.rawThemeResponse.theme) || {};
	}

	@computed
	get show(): boolean {
		if (this.appComponentConfiguration && this.appComponentConfiguration.noDataOptions) {
			const { hide = true } = this.appComponentConfiguration.noDataOptions;
			if (!hide) {
				return true;
			}
		}
		return this.customHasDataValidation(this.hasData);
	}

	@computed
	get showPlaceholder(): boolean {
		return (this.show && !this.customHasDataValidation(this.hasData) && this.noDataPlaceholder !== undefined);
	}

	customHasDataValidation(hasData: boolean): boolean {
		if (this.appComponentConfiguration) {
			const { noDataOptions } = this.appComponentConfiguration;
			if (noDataOptions) {
				const { customValidation } = noDataOptions;
				if (customValidation) {
					const { queryStore } = this.storeContext;
					const { includeDefaultValidation, validateWithDefaultAs, conditionalValidation } = customValidation;
					const conditionalHelper = new helpers.ConditionalHelper(conditionalValidation);
					const isValid = conditionalHelper.matchesWithOptions({ executionOptions: queryStore.executionOptions });
					if (includeDefaultValidation) {
						switch (validateWithDefaultAs) {
							case 'ANY':
								hasData = hasData || isValid;
								break;
							case 'BOTH':
							default:
								hasData = hasData && isValid;
								break;
						}
					}
					else {

						hasData = isValid;
					}
				}
			}
		}
		return hasData;
	}

	@computed
	get noDataPlaceholder(): string | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.noDataOptions && this.appComponentConfiguration.noDataOptions.noDataPlaceholder;
	}

	@computed
	get dataContextSeed(): IQueryContext | undefined {
		const applicationCode = getApplicationCode();
		const keyWithApplicationCode = `${ DATA_CONTEXT_SEED_KEY }${ applicationCode || '' }`;
		if ((window as any)[keyWithApplicationCode]) {
			return (window as any)[keyWithApplicationCode];
		}
		return undefined;
	}

	@action
	async getAppConfig(): Promise<IGetApplicationAppConfigResponseBody> {
		const appFromHydration = getAppHydration(this.manifest, this.appParamsHelper);
		if (appFromHydration) {
			const { applicationConfiguration, applicationStyles } = appFromHydration;
			return {
				applicationConfiguration,
				applicationStyles,
			};
		}
		// We want to use the hard coded configuration when developing locally
		// otherwise fetch it
		let response: IGetApplicationAppConfigResponseBody = {
			applicationConfiguration: CONFIGURATION,
			applicationStyles: STYLES,
		};
		const environment = process.env.NODE_ENV;
		if (environment !== 'development') {
			response = await this.getAppConfigFromApi();
		}
		return response;
	}

	@action
	async getAppConfigFromApi(): Promise<IGetApplicationAppConfigResponseBody> {
		const { rawAppParams = {} } = this.appParamsHelper;
		const { configurationKey = 'default', styleKey } = rawAppParams;
		const urlParams: string[] = [
			configurationKey,
		];
		// Only add the styleKey if it is defined and not default to help with backwards compatibility, until services are deployed.
		if (styleKey && styleKey !== 'default') {
			urlParams.push(styleKey);
		}
		const { kurtosysApiStore } = this.storeContext;
		return await kurtosysApiStore.getAppConfig.execute({
			urlParams,
		});
	}

	@action
	async loadAppTheme(): Promise<void> {
		const themeKey = this.themeKey;
		if (!this.rawThemeResponse || this.rawThemeResponse.name !== themeKey) {
			const { kurtosysApiStore } = this.storeContext;
			try {
				const queryString: any = {
					type: 'apps',
					name: themeKey,
				};
				const themeResponse = await kurtosysApiStore.getTheme.execute({
					queryString,
				});
				if (themeResponse) {
					this.rawThemeResponse = themeResponse;
				}
			}
			catch (ex) {
				console.warn(ex);
			}
		}
	}

	@computed
	get assetsBaseUrl(): string {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.getBaseAddress('');
	}

	@computed
	get assetCacheOptions(): CacheOptions | undefined {
		return (this.coreConfigurations && this.coreConfigurations.assetCacheOptions) || undefined;
	}

	// Leaving this as uncomputed on purpose
	// Hydration is not using an observable
	get assetRegisters(): IApplicationAssetRegister[] | undefined {
		const appHydration = getAppHydration(this.manifest, this.appParamsHelper);
		if (appHydration && appHydration.app && appHydration.app.assetRegister) {
			return appHydration.app.assetRegister;
		}
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get appComponentConfiguration(): IAppConfiguration | undefined {
		const appConfig = this.getComponentConfiguration('app');
		if (appConfig) {
			return appConfig;
		}
		return;
	}

	@computed
	get assets(): IAssets | undefined {
		const assets = this.configuration && this.configuration.assetOverrides;
		if (assets && utils.typeChecks.isNullOrEmpty(assets.baseUrl)) {
			assets.baseUrl = this.assetsBaseUrl;
		}
		return assets;
	}

	@computed
	get configuration(): IConfiguration | undefined {
		if (this.rawConfiguration) {
			return this.rawConfiguration as IConfiguration;
		}
	}
	@computed
	get styles(): IStyles | undefined {
		if (this.rawStyles) {
			if (this.rawTheme) {
				const { theme: stylesTheme = {}, ...styles } = this.rawStyles;
				const dirty = (obj: any) => JSON.parse(JSON.stringify(obj));
				const theme = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'AppendDeepCopy' }, this.rawTheme, dirty(stylesTheme));
				return { theme, ...styles } as IStyles;
			}
			return this.rawStyles as IStyles;
		}
	}
	@computed
	get libraryComponentsConfiguration(): any | undefined {
		return utils.object.deepMergeObjects(LIBRARY_COMPONENTS_CONFIGURATION, (this.configuration && this.configuration.libraryComponents) || {});
	}
	@computed
	get coreConfigurations(): ICoreConfigurations {
		let response: ICoreConfigurations | undefined;
		if (this.configuration && this.configuration.core) {
			response = this.configuration.core;
		}
		if (!response) {
			response = {

			};
		}
		return response;
	}
	@computed
	get theme(): any | undefined {
		return this.styles && this.styles.theme;
	}
	@computed
	get componentConfiguration(): IComponentConfigurations {
		if (this.configuration && this.configuration.components) {
			return this.configuration.components;
		}
		return {};
	}
	@computed
	get componentStyles(): IComponentStyles {
		if (this.rawStyles && this.styles && this.styles.components) {
			return this.styles.components;
		}
		return {};
	}
	getInput(inputKey: keyof IInputs) {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}
	getComponentConfiguration<K extends keyof IComponentConfigurations>(componentKey: K) {
		return this.componentConfiguration[componentKey];
	}
	getComponentStyle<K extends keyof IComponentStyles>(componentKey: K, ...childKeys: string[]): IComponentStyle | undefined {
		let response: IComponentStyle | undefined = this.componentStyles[componentKey] as IComponentStyle;
		if (childKeys) {
			for (const childKey of childKeys) {
				if (response && response.children && (response.children as any)[childKey]) {
					response = (response.children as any)[childKey];
				}
				else {
					response = undefined;
					break;
				}
			}
		}
		return response;
	}
	getDimensions = (): IPixelDimensions => {
		return utils.layout.getHtmlElementSize(this.htmlElement);
	}
}
