import { IConfiguration } from '../models/app/IConfiguration';
import { TranslationStore } from '../components/App/stores/TranslationStore';

export const CONFIGURATION: IConfiguration = {
	allowMultipleAttestations: true,
	autoAttest: {
		parameter: 'attested',
	},
	components: {
		acceptance: {
			mode: 'MULTIPLE',
			items: [
				{
					key: 'terms_and_conditions',
					text: 'Agree to terms and conditions *',
				},
				{
					key: 'privacy_policy',
					text: 'Agree to data privacy policy',
					optional: true,
				},
			],
		},
		app: {
			grid: {
				rows: [
					{
						columns: [
							{
								components: [
									{
										key: 'header',
									},
								],
							},
						],
					},
					{
						columns: [
							{
								components: [
									{
										key: 'selectionWizard',
									},
								],
							},
						],
					},
				],
			},
		},
		callToAction: {
			order: 'rejectThenAccept',
			rejectText: 'Disagree',
			text: 'Agree',
		},
		disclaimer: {
			disclaimers: [
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								key: 'region-is-europe',
								operator: '=',
								value: 'europe',
							},
							{
								field: 'country',
								key: 'country-is-not-swiss',
								operator: '!=',
								value: 'ch',
							},
						],
						key: 'europe_except_swiss',
					},
					type: 'attestation_disclaimer_eu',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								key: 'country-is-swiss',
								operator: '=',
								value: 'ch',
							},
						],
						key: 'country-is-swiss',
					},
					type: 'attestation_disclaimer_swiss',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								key: 'region-is-latam',
								operator: '=',
								value: 'latam',
							},
						],
						key: 'region-is-latam',
					},
					type: 'attestation_disclaimer_latam',
				},
			],
		},
		footnote: {
			text: {
				type: 'attestation_default',
			},
			textProps: {
				hasHtmlContent: true,
			},
		},
		header: {
			grid: {
				rows: [
					{
						columns: [
							{
								components: [
									{
										key: 'title',
									},
								],
							},
						],
					},
				],
			},
			title: {
				text: 'Important Information',
			},
		},
		links: {
			display: 'has-all-selections',
			links: [
				{
					text: 'Privacy Policy',
					target: '_blank',
					redirect: {
						defaultUrl: 'https://www.kurtosys.com/en-{country}/{investorType_category}',
					},
				},
			],
		},
		selection: {
			fields: [],
			initialValues: 'STORAGE',
			mode: 'wizard',
			geolocation: {
				enabled: true,
				cascadingFieldValues: [
					{
						key: 'region',
						value: 'europe',
						conditional: {
							conditions: [
								{
									field: 'country',
									operator: 'not-in',
									value: ['sa'],
								},
							],
						},
					},
					{
						key: 'region',
						value: 'africa',
						conditional: {
							conditions: [
								{
									field: 'country',
									operator: 'in',
									value: ['sa'],
								},
							],
						},
					},
				],
			},
		},
		selectionWizard: {
			fields: [
				{
					key: 'region',
					label: 'Select your <b>REGION</b>',
					options: [
						{
							label: 'Europe',
							value: 'europe',
						},
						{
							label: 'Latin America',
							value: 'latam',
						},
						{
							label: 'Africa',
							value: 'africa',
						},
					],
				},
				{
					conditional: {
						conditions: [
							{
								field: 'region',
								operator: 'not-in',
								value: [
									'latam',
								],
							},
						],
					},
					key: 'country',
					label: 'Select your <b>COUNTRY</b>',
					options: [
						{
							label: 'South Africa',
							value: 'sa',
							countryCode: 'ZAF',
						},
						{
							label: 'Austria',
							value: 'at',
							countryCode: 'AUT',
						},
						{
							label: 'Denmark',
							value: 'dk',
							countryCode: 'DNK',
						},
						{
							label: 'Finland',
							value: 'fi',
							countryCode: 'FIN',
						},
						{
							label: 'France',
							value: 'fr',
							countryCode: 'FRA',
						},
						{
							label: 'Germany',
							value: 'de',
							countryCode: 'DEU',
						},
						{
							label: 'Iceland',
							value: 'is',
							countryCode: 'ISL',
						},
						{
							label: 'Ireland',
							value: 'ie',
							countryCode: 'IRL',
						},
						{
							label: 'Italy',
							value: 'it',
							countryCode: 'ITA',
						},
						{
							label: 'Luxembourg',
							value: 'lu',
							countryCode: 'LUX',
						},
						{
							label: 'Netherlands',
							value: 'nl',
							countryCode: 'NLD',
						},
						{
							label: 'Norway',
							value: 'no',
							countryCode: 'NOR',
						},
						{
							label: 'Portugal',
							value: 'pt',
							countryCode: 'PRT',
						},
						{
							label: 'Spain',
							value: 'es',
							countryCode: 'ESP',
						},
						{
							label: 'Sweden',
							value: 'se',
							countryCode: 'SWE',
						},
						{
							label: 'Switzerland',
							value: 'ch',
							countryCode: 'CHE',
						},
						{
							label: 'United Kingdom',
							value: 'gb',
							countryCode: 'GBR',
						},
						{
							label: 'Rest of the World',
							value: 'rest',
							countryCode: 'default',
						},
					],
				},
				{
					key: 'investorType',
					label: 'How do you <b>IDENTIFY YOURSELF</b>',
					options: [
						{
							category: 'institutional',
							label: 'Consultants',
							value: 'consultants',
						},
						{
							category: 'institutional',
							label: 'Family Office',
							value: 'familyOffice',
						},
						{
							category: 'institutional',
							label: 'Other Institutions',
							value: 'institutional',
						},
						{
							category: 'institutional',
							label: 'Pensions',
							value: 'pensions',
						},
						{
							category: 'institutional',
							label: 'Financial Institutions',
							value: 'financialInstitutions',
						},
						{
							category: 'institutional',
							label: 'Insurance',
							value: 'insurance',
						},
						{
							category: 'intermediary',
							label: 'Wealth Management',
							value: 'intermediary',
						},
					],
				},
			],
		},
	},
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	name: 'ksys-app-attestation',
	onChangeOptions: {
		cultureChange: {
			defaultValue: 'en-GB',
			key: 'cultureChange',
			options: [
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'be',
								],
							},
							{
								field: 'investorType',
								operator: 'in',
								value: [
									'individual',
								],
							},
						],
					},
					label: '',
					value: 'fr-FR',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'be',
								],
							},
							{
								field: 'investorType',
								operator: 'in',
								value: [
									'institutional',
								],
							},
						],
					},
					label: '',
					value: 'en-GB',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'fr',
								],
							},
						],
					},
					label: '',
					value: 'fr-FR',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'ch',
								],
							},
							{
								field: 'investorType',
								operator: 'in',
								value: [
									'intermediary',
								],
							},
						],
					},
					label: '',
					value: 'en-GB',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'de',
									'at',
									'ch',
								],
							},
						],
					},
					label: '',
					value: 'de-DE',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'it',
								],
							},
						],
					},
					label: '',
					value: 'it-IT',
				},
				{
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'es',
								],
							},
						],
					},
					label: '',
					value: 'es-ES',
				},
				{
					allowedExplicitDefaultValues: [
						'en-GB',
						'zh-HK',
					],
					conditional: {
						conditions: [
							{
								field: 'country',
								operator: 'in',
								value: [
									'hk',
								],
							},
						],
					},
					label: '',
					value: '{useExplicitDefaultValue}',
				},
			],
			type: 'CONDITIONAL_VALUE',
		},
	},
	redirect: {
		conditionalRedirects: [
			{
				conditional: {
					conditions: [
						{
							field: 'region',
							operator: 'in',
							value: [
								'latam',
							],
						},
					],
				},
				url: '/{language}-{region}/{investorType_category}',
			},
		],
		defaultUrl: '/{language}-{country}/{investorType_category}',
		options: {
			preserveOriginPath: true,
		},
	},
	rejectRedirect: {
		defaultUrl: '/home/test',
	},
	storage: {
		expiry: {
			period: 'MONTHS',
			value: 3,
		},
		obfuscated: false,
		type: 'LOCAL',
	},
	token: '144ef90f-a4f6-46dd-a541-e65deeacaa26',
};

