import React, { Component } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import { Modal } from '../Modal/Modal';
import Footnote from '../Footnote/Footnote';
import Wrapper from './styledComponents/Wrapper';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import Grid from '@kurtosys/ksys-app-components/dist/components/base/Grid';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';

@inject('appStore', 'selectionStore', 'disclaimerStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}

	renderAttestation = () => {
		const { appStore, selectionStore, disclaimerStore, className } = this.props;

		if (!appStore || !selectionStore || !disclaimerStore) {
			return null;
		}
		const { theme, show, showPlaceholder, noDataPlaceholder, libraryComponentsConfiguration, getTranslateFunction, assets, assetCacheOptions, assetRegisters, components, grid, hasSelectionWizard } = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholderComponent = <NoDataPlaceholder>{ noDataPlaceholder }</NoDataPlaceholder>;

		const translate = getTranslateFunction();
		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters }>
				<Wrapper className={ className }>
					{ showPlaceholder && noDataPlaceholderComponent }
					{ !showPlaceholder && <Grid components={ components as any } grid={ grid } /> }
					{ !hasSelectionWizard && <Footnote /> }
				</Wrapper>
			</Application>
		);
	}

	render() {
		const { appStore, className } = this.props;

		if (!appStore) {
			return null;
		}

		if (appStore.isAttestationDisabled) {
			return null;
		}
		const { isAttestationBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (isAttestationBootstrapped) {
			if (appStore.isAttestationActive) {
				return <Modal open={ appStore.isAttestationRequired }>{ this.renderAttestation() }</Modal>;
			}
			return this.renderAttestation();
		}
		if (!appStore.isAttestationActive) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}

		return null;
	}
}

export default InjectedStyledComponent(App, 'app');
