import { helpers } from "@kurtosys/ksys-app-template";
import { StoreContext } from "../configuration/StoreContext";
import { Manifest } from "../configuration/Manifest";
import { TEMPLATE_STORE } from "../initialize";

export function getStoreContextForElement(
    element: HTMLElement,
    url: string,
    manifest: Manifest,
    storeKey?: string,
) {
    storeKey = storeKey || getStoreKey(element, url, manifest);
    if (storeKey) {
        if (!(window as any)[storeKey]) {
            (window as any)[storeKey] = new StoreContext(
                element,
                url,
                TEMPLATE_STORE,
                manifest,
            );
        }
        const storeContext = (window as any)[storeKey] as StoreContext;
        return storeContext;
    }
}

export function getStoreKey(
    element: HTMLElement,
    url: string,
    manifest: Manifest,
) {
    // Refactor to allow for static retrieval of the appParams
    const helper = new helpers.AppParamsHelper(element, url);
    const appParams = helper.getAppParamsFromElement();
    if (appParams.storeKey) {
        return appParams.storeKey;
    }
    // Check if there is an explicit key that is supposed to be used for the store
    // that is defined on the element
    const {
        configurationKey = "default",
        styleKey = "default",
        applicationCode = "",
        inputMode = "passive",
    } = appParams;

    const keys = [manifest.storeKey, configurationKey, styleKey];

    if (applicationCode.length > 0) {
        keys.push(applicationCode);
    }

    const { inputs = {} } = helper;
    const inputKeys = Object.keys(inputs).sort();
    const inputText = `(inputs=${inputKeys
        .map((key) => `${key}:${inputs[key]}`)
        .join(",")})`;
    keys.push(inputText);
    return keys.join("-");
}
