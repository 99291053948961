import { computed, action, observable, reaction } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISelectionWizardConfiguration } from '../models';
import { ISelectionWizardField } from '../models/ISelectionWizardField';
import { helpers, utils } from '@kurtosys/ksys-app-template';
import { ISelectionFieldOption } from '../../../models/app/ISelectionFieldOption';
import { ISelectionOption } from '../../../models/app/ISelectionOption';
export class SelectionWizardStore extends StoreBase {
	static componentKey: 'selectionWizard' = 'selectionWizard';
	@observable selectedFieldKey: string | undefined;
	@observable.ref activeFields: ISelectionWizardField[] = [];
	@computed
	get configuration(): ISelectionWizardConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SelectionWizardStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		if (this.configuration) {
			this.loadActiveFields();
			reaction(
				() => this.storeContext.selectionStore.hasAllSelections,
				(hasAllSelections) => {
					if (hasAllSelections) {
						this.selectedFieldKey = undefined;
					}
				},
			);
		}
	}

	@computed
	get selectedField(): ISelectionWizardField | undefined {
		const navigatorFields = this.navigatorFields || [];
		if (this.selectedFieldKey) {
			const existingField = navigatorFields.find(navigatorField => navigatorField.key === this.selectedFieldKey);
			if (existingField) {
				return existingField;
			}
		}
		// Return the first defined field
		return navigatorFields.find(navigatorField => !!navigatorField);
	}

	@computed
	get options(): ISelectionOption[] {
		const { selectionStore } = this.storeContext;
		const field = this.selectedField;
		let options: ISelectionOption[] = [];
		if (selectionStore && field) {
			options = selectionStore.getOptions(field);
		}
		return options;
	}

	@computed
	get fields(): ISelectionWizardField[] {
		let response: ISelectionWizardField[] = [];
		if (this.configuration && this.configuration.fields) {
			response = this.configuration.fields;
		}
		response = utils.collection.sortBy(response, field => (field.order || 99999).toString());
		return response;
	}

	@computed
	get navigatorFields(): ISelectionWizardField[] {
		const { selectionStore } = this.storeContext;
		const activeFields = this.activeFields;
		const navigatorFields: ISelectionWizardField[] = [];
		const currentIndex = this.selectedFieldActiveFieldIndex;
		for (let index = 0; index < activeFields.length; index++) {
			const field = activeFields[index];
			const value = selectionStore.getValue(field);
			if (index === currentIndex || !utils.typeChecks.isNullOrUndefined(value)) {
				const { autoSelectSingleOption } = field;
				if (autoSelectSingleOption) {
					const fieldOptions = selectionStore.getOptions(field);
					field.disabled = fieldOptions.length === 1;
				}
				navigatorFields.push(field);
			}
		}
		return navigatorFields;
	}

	@action
	loadActiveFields(): void {
		const { selectionStore } = this.storeContext;
		const values = selectionStore.values || {};
		const fields = this.fields;
		const activeFields = [];
		for (const field of fields) {
			const { conditional } = field;
			// Check if the field should be conditional on other values
			if (conditional) {
				const conditionalHelper = new helpers.ConditionalHelper(conditional);
				if (conditionalHelper.matches(values)) {
					activeFields.push(field);
				}
			}
			else {
				activeFields.push(field);
			}
		}
		this.activeFields = activeFields;
		selectionStore.fields = this.activeFields;
		if (!selectionStore.hasAllSelections && !this.selectedFieldKey && activeFields && activeFields.length > 0) {
			this.selectedFieldKey = activeFields[0].key;
		}
	}

	@computed
	get selectedFieldActiveFieldIndex(): number {
		return (this.activeFields || []).findIndex(activeField => activeField.key === this.selectedFieldKey);
	}

	@action
	handleOptionSelect = (value: any): void => {
		const { selectionStore } = this.storeContext;
		if (this.selectedField) {
			selectionStore.setValue(this.selectedField, value);
			this.loadActiveFields();
			const activeFields = this.activeFields;
			const currentIndex = this.selectedFieldActiveFieldIndex;
			if (currentIndex + 1 < activeFields.length) {
				this.selectedFieldKey = activeFields[currentIndex + 1].key;
			}
			else {
				this.selectedFieldKey = undefined;
			}
		}
	}

	@action
	handleNavigatorItemSelect = (field: ISelectionWizardField): void => {
		const { selectionStore } = this.storeContext;
		if (this.selectedField) {
			selectionStore.setValue(field, undefined);
			this.loadActiveFields();
			this.selectedFieldKey = field.key;
		}
	}



	// Check if all selections are made for the wizard

	//

}