import { helpers, utils } from '@kurtosys/ksys-app-template';
import { Manifest } from '../../../configuration/Manifest';
import { IAttestation } from '../../../models/app/IAttestation';
import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, observable, action } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IConfigurationRedirect } from '../../../models/app/IConfigurationRedirect';
import { IConfigurationRedirectConditional } from '../../../models/app/IConfigurationRedirectConditional';
import { IConfigurationRedirectOptions } from '../../../models/app//IConfigurationRedirectOptions';
import { IConfigurationStorage } from '../../../models/app/IConfigurationStorage';
import Acceptance from '../../Acceptance/Acceptance';
import CallToAction from '../../CallToAction/CallToAction';
import Disclaimer from '../../Disclaimer/Disclaimer';
import Header from '../../Header/Header';
import Selection from '../../Selection/Selection';
import Checkbox from '@kurtosys/ksys-app-components/dist/components/base/Checkbox';
import SelectionWizard from '../../SelectionWizard/SelectionWizard';
import Footnote from '../../Footnote/Footnote';
import Links from '../../Links/Links';
import { obfuscate, deObfuscate, isObfuscated } from '../../../utils/obfuscation';
import { IAttestationValidation } from '../../../models/app/IAttestationValidation';
import { IGrid } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGrid';
import { TStorageType } from '../../../models/app/TStorageType';
import { isNullOrEmpty, isNullOrUndefined } from '@kurtosys/ksys-app-template/dist/utils/typeChecks';
import { geolocate } from '../../../utils/geolocate';
import { IStorageOptions } from '../models/IStorageOptions';
import { IStorageOption } from '../models/IStorageOption';
import { ISelectionValues } from '../../Selection/models/ISelectionValues';

/* [Component: appStoreComponentImport] */

export class AppStore extends AppStoreBase {

	url: URL;
	@observable isInitialized = false;

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
		this.url = new URL(url);
	}

	async customInitializeAfter() {
		const { disclaimerStore, selectionWizardStore } = this.storeContext;
		// Initial API call is handled by disclaimerStore
		await disclaimerStore.loadDisclaimers();
		await selectionWizardStore.initialize();
		if (this.isAttestationActive && this.redirectByPreviousAttestation) {
			this.redirectToPreviousAttestation();
		}
		this.checkAutoAttestation();
		this.setSelections();
		this.isInitialized = true;
	}

	@computed
	get isAttestationBootstrapped() {
		if (
			!this.isInitialized ||
			!this.isBootstrapped ||
			!this.configuration ||
			!this.styles ||
			!this.components ||
			!this.grid
		) {
			return false;
		}

		return true;
	}

	@computed
	get defaultAttestationVersion(): string {
		return `v1.0.1`;
		// Reset once the client is using the same version
		// return `v${ this.manifest.version }`;
	}

	@computed
	get attestationVersion(): string {
		return (this.configuration && this.configuration.version) || this.defaultAttestationVersion;
	}

	@computed
	get hasData(): boolean {
		// TODO: Each Application should put custom show logic here: "return this.storeContext[component store].hasData;"
		return true;
	}

	@computed
	get components(): IAppComponents {
		return {
			acceptance: {
				key: 'acceptance',
				component: Acceptance,
			},
			callToAction: {
				key: 'callToAction',
				component: CallToAction,
			},
			disclaimer: {
				key: 'disclaimer',
				component: Disclaimer,
			},
			header: {
				key: 'header',
				component: Header,
			},
			selection: {
				key: 'selection',
				component: Selection,
			},
			checkbox: {
				key: 'checkbox',
				component: Checkbox,
			},
			selectionWizard: {
				key: 'selectionWizard',
				component: SelectionWizard,
			},
			footnote: {
				key: 'footnote',
				component: Footnote,
			},
			links: {
				key: 'links',
				component: Links,
			},
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get hasSelectionWizard(): boolean {
		return this.componentConfiguration && !isNullOrUndefined(this.componentConfiguration.selectionWizard);
	}

	@computed
	get grid(): IGrid | undefined {
		const appComponentConfiguration = this.getComponentConfiguration('app');
		if (appComponentConfiguration && appComponentConfiguration.grid) {
			return appComponentConfiguration.grid;
		}
	}

	@computed
	get hasSelectionsAndDisclaimers(): boolean {
		return this.storeContext &&
			this.storeContext.selectionStore &&
			this.storeContext.selectionStore.hasAllSelections &&
			this.storeContext.disclaimerStore &&
			this.storeContext.disclaimerStore.hasDisclaimers;
	}

	@action setAttestationAndRedirect = () => {
		if (this.setAttestation()) {
			this.redirect();
		}
	}

	@action rejectAndRedirect = () => {
		this.redirect(true);
	}

	isCmsEditMode = (): boolean => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { cmsEditMode: { selector = 'body.elementor-editor-active' } = {} } = rootConfiguration;
			if (selector) {
				return window.parent.document.querySelectorAll(selector).length > 0;
			}
			return false;
		}
		return false;
	}

	@computed
	get isAttestationDisabled() {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { disableAttest } = rootConfiguration;

			// We have to hard code the isSnapshot query param as this is required for wordpress snapshots.
			const disableParameters = ['isSnapshot'];
			if (disableAttest && disableAttest.parameter) {
				disableParameters.push(disableAttest.parameter);
			}

			if (Array.isArray(disableParameters) && disableParameters.length > 0) {
				for (let i = 0; i <= disableParameters.length; i++) {
					if (this.url.searchParams.has(disableParameters[i]) && this.url.searchParams.get(disableParameters[i]) === 'true') {
						return true;
					}
				}
			}
		}
		return false;
	}

	checkAutoAttestation = () => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { autoAttest } = rootConfiguration;
			if (autoAttest && this.url.searchParams.has(autoAttest.parameter)) {
				this.setAttestationFromQueryString(autoAttest.parameter);
			}
		}
	}

	@computed
	get attestationValidation(): IAttestationValidation | undefined {
		return this.configuration && this.configuration.attestationValidation;
	}

	getPreviousAttestation = (storageType?: TStorageType) => {
		let response: IAttestation[] = [];
		const acceptanceStorage = this.storeContext.acceptanceStore.storage;
		const acceptanceResponse = this.getAttestationFromStorage(acceptanceStorage, storageType);
		if (acceptanceResponse) {
			response = [
				...(Array.isArray(acceptanceResponse) ? acceptanceResponse : [acceptanceResponse]),
			];
		}

		const rootConfigurationStorage = this.configuration && this.configuration.storage;
		const rootConfigurationResponse = this.getAttestationFromStorage(rootConfigurationStorage, storageType);
		if (rootConfigurationResponse) {
			response = [
				...response,
				...(Array.isArray(rootConfigurationResponse) ? rootConfigurationResponse : [rootConfigurationResponse]),
			];
		}

		if (isNullOrEmpty(response)) {
			return undefined;
		}
		return response = response.filter(attestation => (attestation.version || this.defaultAttestationVersion) === this.attestationVersion);
	}

	getAttestationFromStorage(storage?: IConfigurationStorage, targetStorageType?: TStorageType): IAttestation | IAttestation[] | undefined {
		if (storage) {
			const { type } = storage;
			if (type === (targetStorageType || type)) {
				switch (type) {
					case 'SESSION':
						return this.getAttestationSessionStorage(storage);
					case 'LOCAL':
					default:
						return this.getAttestationLocalStorage(storage);
				}
			}
		}
	}

	setAttestation = () => {
		let isSuccess = false;
		const values = this.storeContext.selectionStore.rawValues;
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { accepted, storage: acceptanceStorage } = this.storeContext.acceptanceStore;
			const storage = accepted && acceptanceStorage || rootConfiguration.storage;

			if (storage) {
				const { type } = storage;
				switch (type) {
					case 'SESSION':
						isSuccess = this.setAttestationSessionStorage(values);
						break;
					case 'LOCAL':
					default:
						isSuccess = this.setAttestationLocalStorage(values);
						break;
				}
			}
		}
		return isSuccess;
	}
	getStorageOptions = (storageType: TStorageType) => {
		let options: IStorageOptions | undefined;
		const localStorageOption: IStorageOption = {
			key: this.localStorageKey,
			storage: localStorage,
		};
		const sessionStorageOption: IStorageOption = {
			key: this.sessionStorageKey,
			storage: sessionStorage,
		};
		switch (storageType) {
			case 'LOCAL':
				options = {
					target: localStorageOption,
					clear: sessionStorageOption,
				};
				break;
			case 'SESSION':
				options = {
					target: sessionStorageOption,
					clear: localStorageOption,
				};
				break;
		}
		return options;
	}
	getStorageValues = (storage: IConfigurationStorage, storageType: TStorageType) => {
		const options = this.getStorageOptions(storageType);
		const { expiry } = storage;
		let attestationString = options && options.target && options.target.storage && options.target.storage.getItem(options.target.key);
		if (attestationString) {

			if (isObfuscated(attestationString)) {
				attestationString = deObfuscate(attestationString);
			}

			const attestationConversion = utils.json.jsonTryParse(attestationString);
			if (attestationConversion.isValid) {
				if (attestationConversion.value) {
					let attestations = attestationConversion.value as IAttestation | IAttestation[];
					if (!Array.isArray(attestations)) {
						attestations = [attestations];
					}
					if (Array.isArray(attestations)) {
						const validAttestations = utils.collection.sortByDate(attestations.filter((attestation) => {
							const { time } = attestation;
							// Check the expiration
							const timeAsDate = new Date(Date.parse(time));
							const hasExpired = !expiry ? false : utils.date.hasDateExpired(timeAsDate, expiry);
							return !hasExpired;
						}), value => value.time, 'DESC');
						if (validAttestations.length > 0) {
							if (validAttestations.length !== attestations.length) {
								this.resetStorageValues(storageType, validAttestations);
							}
							return validAttestations;
						}
					}
				}
				options && options.target && options.target.storage && options.target.storage.removeItem(options.target.key);
			}
		}
		return;
	}
	setStorageValues = (storageType: TStorageType, values: any) => {
		const options = this.getStorageOptions(storageType);
		if (options) {
			const attestation: IAttestation = {
				values,
				time: new Date().toUTCString(),
				version: this.attestationVersion,
			};
			if (this.configuration && this.configuration.allowMultipleAttestations) {
				const newCollection = [
					attestation,
				];
				const previousAttestation = this.getPreviousAttestation(storageType);
				if (previousAttestation) {
					if (Array.isArray(previousAttestation)) {
						newCollection.push(...previousAttestation);
					}
					else {
						newCollection.push(previousAttestation);
					}
				}

				// Remove older duplicates
				const cleanCollection: IAttestation[] = [];
				const { selectionStore } = this.storeContext;
				const { fields, getValue } = selectionStore;
				if (fields) {
					const mappingObject: any = {};
					for (const attestation of newCollection) {
						const { values } = attestation;
						const keyPieces = fields.map((field) => {
							const { key } = field;
							const value = getValue(field, undefined, values);
							return `${ key }:${ value }`;
						});
						const key = keyPieces.join(';');
						if (!mappingObject[key]) {
							mappingObject[key] = attestation;
							cleanCollection.push(attestation);
						}
					}
				}
				options.target.storage.setItem(options.target.key, this.getValueForStorage(cleanCollection));
			}
			else {
				options.clear.storage.removeItem(options.clear.key);
				options.target.storage.setItem(options.target.key, this.getValueForStorage(attestation));
			}
		}
		return true;
	}
	resetStorageValues = (storageType: TStorageType, values: any) => {
		const options = this.getStorageOptions(storageType);
		if (options && options.target && options.target.storage) {
			options.target.storage.setItem(options.target.key, this.getValueForStorage(values));
		}
	}
	localStorageKey = 'ksys-attestation';
	setAttestationLocalStorage = (values: any) => {
		return this.setStorageValues('LOCAL', values);
	}

	getAttestationLocalStorage = (storage: IConfigurationStorage): IAttestation | IAttestation[] | undefined => {
		return this.getStorageValues(storage, 'LOCAL');
	}

	sessionStorageKey = 'ksys-attestation';
	setAttestationSessionStorage = (values: any) => {
		return this.setStorageValues('SESSION', values);
	}

	getAttestationSessionStorage = (storage: IConfigurationStorage): IAttestation | IAttestation[] | undefined => {
		return this.getStorageValues(storage, 'SESSION');
	}

	setAttestationFromQueryString = (autoAttestParameter: string) => {
		const selectionFieldKeys = this.storeContext.selectionStore.fields.map(field => field.key);

		this.url.searchParams.forEach((value, key) => {
			const selectionField = this.storeContext.selectionStore.fields.find(field => field.key === key);
			if (selectionField) {
				this.storeContext.selectionStore.setValue(selectionField, value);
			}
		});

		this.cleanQueryStringParameters([autoAttestParameter, ...selectionFieldKeys]);
		this.setAttestation();
	}

	setSelections = () => {
		const { selection } = this.componentConfiguration;
		if (selection) {
			const { initialValues } = selection;
			initialValues === 'EMBEDDED_INPUT' ? this.setSelectionValuesFromInputs() : this.setSelectionValuesFromStorage();
			this.setSelectionValuesByGeolocation();
		}
	}

	setSelectionValuesFromInputs = () => {
		if (!this.appParamsHelper.values) {
			return false;
		}

		const { inputs } = this.appParamsHelper.values;

		if (!inputs) {
			return false;
		}

		this.setSelectionValues(inputs);
	}

	setSelectionValuesFromStorage = () => {
		let previousAttestation = this.getPreviousAttestation('SESSION');
		if (!previousAttestation) {
			previousAttestation = this.getPreviousAttestation();
		}
		if (!isNullOrEmpty(previousAttestation)) {
			this.setSelectionValues(previousAttestation[0].values);
		}
	}

	setSelectionValues = (values: { [key: string]: any }) => {
		const keys = Object.keys(values);
		const selectionFields = this.storeContext.selectionStore.fields.filter(field => keys.includes(field.key));
		selectionFields.forEach((selectionField) => {
			this.storeContext.selectionStore.setValue(selectionField, values[selectionField.key]);
			// If using the wizard, refresh the active fields after setting each value as the conditional matches may have changed
			if (this.storeContext.selectionStore.mode === 'wizard') {
				this.storeContext.selectionWizardStore.loadActiveFields();
			}
		});
	}

	cleanQueryStringParameters = (parameters: string[] = []) => {
		parameters.forEach((param) => {
			this.url.searchParams.delete(param);
		});

		window.history.replaceState(null, '', this.url.toString());
	}

	redirect = (isRejection: boolean = false, values?: ISelectionValues) => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { redirect, rejectRedirect } = rootConfiguration;
			const redirectToUse = isRejection ? rejectRedirect : redirect;
			if (redirectToUse) {
				const redirectPath = this.getRedirectPath(redirectToUse, values);
				if (redirectPath) {
					document.location.href = redirectPath;
				}
			}
		}
	}

	getRedirectPath = (redirect: IConfigurationRedirect | undefined, values?: ISelectionValues) => {
		if (redirect) {
			const selections = values || this.storeContext.selectionStore.rawValues;
			const { conditionalRedirects, defaultUrl, options = {} } = redirect;
			let validRedirect: IConfigurationRedirectConditional | undefined;
			if (conditionalRedirects) {
				validRedirect = conditionalRedirects.find((conditionalRedirect) => {
					const { conditional } = conditionalRedirect;
					const conditionalHelper = new helpers.ConditionalHelper(conditional);
					return conditionalHelper.matches(selections);
				});
			}
			const url = (validRedirect && validRedirect.url) || defaultUrl;
			const redirectOptions = (validRedirect && validRedirect.options) || options;
			const replacedUrl = utils.replacePlaceholders(selections, url);
			return this.getRedirectUrl(replacedUrl, redirectOptions);
		}
	}

	getRedirectUrl = (redirectString: string, options: IConfigurationRedirectOptions): string => {
		const redirectUrl = new URL(redirectString, window.location.origin); // if url is absolute, base is ignored

		if (options.preserveOriginPath) {
			const redirectUrlSegments = redirectUrl.pathname.split('/');
			const currentUrlSegments = this.url.pathname.split('/');
			const remainingUrlSegments = currentUrlSegments.slice(redirectUrlSegments.length);

			redirectUrl.pathname = [...redirectUrlSegments, ...remainingUrlSegments].join('/');
		}

		if (options.preserveOriginHash) {
			redirectUrl.hash = this.url.hash;
		}

		if (options.preserveOriginQueryString) {
			this.url.searchParams.forEach((value, key) => {
				if (!redirectUrl.searchParams.has(key)) {
					redirectUrl.searchParams.set(key, value);
				}
			});
		}

		return redirectUrl.toString();
	}

	@computed
	get isAttestationActive(): boolean {
		if (!this.appParamsHelper.values) {
			return false;
		}

		const { inputs } = this.appParamsHelper.values;

		if (!inputs) {
			return false;
		}

		return inputs.mode === 'active';
	}

	@computed
	get isAttestationRequired(): boolean {

		if (!this.appParamsHelper.values || this.isCmsEditMode()) {
			return false;
		}

		const { inputs } = this.appParamsHelper.values;
		const previousAttestation = this.getPreviousAttestation();

		if (!previousAttestation || !inputs) {
			return true;
		}
		if (Array.isArray(previousAttestation) && previousAttestation.length === 0) {
			return true;
		}

		const attestations = !Array.isArray(previousAttestation) ? [previousAttestation] : previousAttestation;

		let hasValidAttestation: boolean = false;
		const fields = this.storeContext.selectionStore.fields;

		// We can exclude certain fields from the check, an example would be when we need the language
		// but it does not change the users attestation
		const attestationFields = (fields || []).filter(field => !field.excludeFromActiveAttestationCheck);

		for (const attestation of attestations) {
			const { values } = attestation;

			if (this.attestationValidation && this.attestationValidation.mode === 'conditionals' && this.attestationValidation.conditional) {
				// Using validation config, validate against previous attestations
				const conditionalHelper = new helpers.ConditionalHelper(this.attestationValidation.conditional);
				hasValidAttestation = conditionalHelper.matches(values);
			}
			else {
				// using field config, validate previous attestations against inputs provided
				const nonMatchingAttestationValue = attestationFields.find((field) => {
					const selectedValue = values[field.key];
					const inputValue = inputs[field.key];

					if (selectedValue === inputValue) {
						return false;
					}
					// Options have an optional category, if the category of the attestation option matches the category
					// of the input option then we don't need to attest again.
					// FCE-1263 - BNY Attestation | Allowing for Multiple Investor Types in dropdown to behave as one Investor Type
					// https://kurtosys-prod-eng.atlassian.net/browse/FCE-1263
					const selectedOption = (field.options || []).find(option => option.value === selectedValue);
					const inputOption = (field.options || []).find(option => option.value === inputValue);

					const selectedCategory = selectedOption && selectedOption.category;
					const inputCategory = inputOption && inputOption.category;

					// If either category is undefined or null then we need to attest again
					return !selectedCategory || !inputCategory || selectedCategory !== inputCategory;
				});

				if (!nonMatchingAttestationValue) {
					hasValidAttestation = true;
				}
			}

			if (hasValidAttestation) {
				break;
			}
		}

		// If any value is false, an attestation is required
		return !hasValidAttestation;
	}

	getValueForStorage = (value: IAttestation | IAttestation[]): string => {
		let obfuscated: boolean = false;
		const rootConfiguration = this.configuration;

		if (rootConfiguration) {
			const { storage } = rootConfiguration;
			if (storage) {
				({ obfuscated = true } = storage);
			}
		}

		if (obfuscated) {
			return obfuscate(JSON.stringify(value));
		}

		return JSON.stringify(value);
	}

	setSelectionValuesByGeolocation() {
		const { selectionStore, selectionWizardStore } = this.storeContext;
		if (selectionStore && selectionStore.geolocationConfig.enabled) {
			const onComplete = (code: string) => {
				const values = this.getGeolocationValues(code);
				if (isNullOrEmpty(values.country)) {
					return;
				}
				this.setSelectionValues(values);

				if (selectionWizardStore && !utils.typeChecks.isNullOrEmpty(selectionWizardStore.fields)) {
					for (const field of selectionWizardStore.fields) {
						if (values[field.key] === undefined) {
							selectionWizardStore.handleNavigatorItemSelect(field);
							break;
						}
					}
				}

			};
			geolocate(onComplete, selectionStore.geolocationConfig.coordinates);
		}
	}

	getGeolocationValues(code: string) {
		const { geolocationConfig: geolocation } = this.storeContext.selectionStore;
		const values: { [key: string]: string } = { country: this.getCountryValue(code) };
		if (geolocation && !utils.typeChecks.isNullOrEmpty(geolocation.cascadingFieldValues)) {
			for (const field of geolocation.cascadingFieldValues) {
				const { key, value, conditional } = field;
				if (conditional) {
					const conditionalHelper = new helpers.ConditionalHelper(conditional);
					if (conditionalHelper.matches(values)) {
						values[key] = value;
					}
				}
				else {
					values[key] = value;
				}
			}
		}
		return values;
	}

	getCountryValue(code: string) {
		const { selectionStore, selectionWizardStore } = this.storeContext;
		const fields = selectionStore.fields || selectionWizardStore.fields;

		const options = fields
			.filter(field => field.key === 'country' && field.options)
			.map(field => field.options || [])
			.reduce((total, options) => {
				return [...total, ...options];
			});
		for (const option of options) {
			if (option.countryCode === code || option.value === code) {
				return option.value;
			}
		}
		for (const option of options) {
			if (option.countryCode === 'default') {
				return option.value;
			}
		}
		return '';
	}

	@computed
	get redirectByPreviousAttestation(): Boolean {
		return this.getInput('redirectByPreviousAttestation') === 'true';
	}

	redirectToPreviousAttestation() {
		const previousAttestation: IAttestation[] | undefined = this.getPreviousAttestation();
		if (previousAttestation && previousAttestation.length > 0) {
			// getStorageValues() sorts by time DESC. Take latest attestation
			const attestationValues: ISelectionValues = previousAttestation[0].values;
			this.redirect(false, attestationValues);
		}
		return false;
	}
}
