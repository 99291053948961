import { helpers } from '@kurtosys/ksys-app-template';
import { triggerEvent } from './events';
import { Manifest } from '../configuration/Manifest';
import { IStyles } from '../models/app/IStyles';
import { IConfiguration } from '../models/app/IConfiguration';
import { IInputs } from '../models/app/IInputs';
import { IAppRegistry } from '../models/app/IAppRegistry';
import { IAppsHydration } from '../models/app/IAppsHydration';
import { getHydrationPoint, HYDRATION_KEY } from '../initialize';
import { IAppsResponse } from '../models/commonTypes';

export function getElementsForApp(manifest: Manifest): HTMLElement[] {
	const ksysAppTemplateIdDataKey = 'data-ksys-app-template-id';
	const ksysAppTemplateVersionDataKey = 'data-ksys-app-template-version';
	const elements = document.querySelectorAll(`[${ ksysAppTemplateIdDataKey }="${ manifest.ksysAppTemplateId }"]`);
	const elementsWithCombinedVersion = document.querySelectorAll(`[${ ksysAppTemplateIdDataKey }="${ manifest.ksysAppTemplateId }-${ manifest.version }"]`);
	let elementsCollection: HTMLElement[] = [];
	if (elements) {
		elementsCollection = Array.from(elements) as HTMLElement[];
		const versions = Object.keys(elementsCollection.reduce((acc: { [key: string]: boolean }, element) => {
			const version = element.getAttribute(ksysAppTemplateVersionDataKey);
			if (version) {
				acc[version] = true;
			}
			return acc;
		}, { [manifest.version]: true }));
		if (versions && versions.length > 0) {
			const highestVersion = versions.sort().reverse()[0];
			elementsCollection = elementsCollection.filter((element) => {
				const version = element.getAttribute(ksysAppTemplateVersionDataKey);
				return (!version && manifest.version === highestVersion) || version === manifest.version;
			});
		}
	}
	if (elementsWithCombinedVersion) {
		elementsCollection.push(
			...Array.from(elementsWithCombinedVersion) as HTMLElement[],
		);
	}
	return elementsCollection;
}

export function getAppKey(manifest: Manifest, appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>) {
	const { rawAppParams = {} } = appParamsHelper;
	const {
		configurationKey = 'default',
		styleKey = 'default',
		ksysAppTemplateVersion: version = 'v1.0.0',
		applicationCode = '',
	} = rawAppParams;
	return `${ manifest.ksysAppTemplateId }-${ version }|${ configurationKey }|${ styleKey }${ applicationCode.length > 0 ? `|${ applicationCode }` : '' }`;
}

function getAppRegistry(): IAppRegistry {
	const key = '__ksys-app-registry__';
	if (!(window as any)[key]) {
		(window as any)[key] = {};
	}
	return (window as any)[key];
}

export function registerAppStart(manifest: Manifest, appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>) {
	const appKey = getAppKey(manifest, appParamsHelper);
	const appRegistry = getAppRegistry();
	appRegistry[appKey] = {
		appKey,
		status: 'started',
	};
}

export function registerAppLoaded(manifest: Manifest, appParamsHelper: helpers.AppParamsHelper<IInputs, IConfiguration, IStyles>) {
	const appKey = getAppKey(manifest, appParamsHelper);
	const appRegistry = getAppRegistry();
	const app = appRegistry[appKey];
	if (app) {
		app.status = 'loaded';
	}
	triggerEvent(`ksys-app-loaded`, appParamsHelper.element);
	setDocDoneIfReady();
}

export function areAllAppsLoaded() {
	const appRegistry = getAppRegistry();
	let allLoaded = true;
	for (const key of Object.keys(appRegistry)) {
		const app = appRegistry[key];
		allLoaded = allLoaded && app.status === 'loaded';
		if (!allLoaded) {
			break;
		}
	}
	return allLoaded;
}

export function setDocDoneIfReady() {
	const allLoaded = areAllAppsLoaded();
	if (allLoaded) {
		(window as any)['docdone'] = true;
		triggerEvent('ksys-apps-loaded');
	}
}

export function getAppHydration(manifest: Manifest, appParamsHelper?: helpers.AppParamsHelper<any, any, any>) {
	if (appParamsHelper) {
		const hydrationId = getAppKey(manifest, appParamsHelper);
		const hydrationPoint = getHydrationPoint();
		return hydrationPoint && hydrationPoint.appsByKey && hydrationPoint.appsByKey[hydrationId];
	}
}

export function getManagerHydration() {
	const hydrationPoint = getHydrationPoint();
	return hydrationPoint && hydrationPoint.manager;
}

export function setHydrationPoint(appsResponse: IAppsResponse) {
	const appsHydration: IAppsHydration = {
		...appsResponse,
		appsByKey: {
			...pivotCollection((appsResponse.apps || []), appResponse => appResponse.app.id || 'NO_ID'),
			...pivotCollection((appsResponse.apps || []), appResponse => `${ appResponse.app.id }|${ appResponse.app.applicationCode }`),
		},
	};
	(window as any)[HYDRATION_KEY] = appsHydration;
}


export function pivotCollection<T>(collection: T[], getKeyFn: (value: T) => string, getValueFn: (value: T) => any = (value => value)): { [key: string]: T } {
	return (collection || []).reduce((acc: { [key: string]: any }, element) => {
		const key = getKeyFn(element);
		acc[key] = getValueFn(element);
		return acc;
	}, {});
}